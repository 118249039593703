import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faUsers, faChartLine, faHandshake, faTools, faGamepad, faDollarSign, faThumbsUp, faRobot, faDatabase, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faRedditAlien, faTwitter, faFacebook, faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function PublishersCRMPage() {

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Influencer CRM | Glitch - Manage Influencer Campaigns Effectively</title>
        <meta name="description" content="Optimize your influencer marketing with Glitch's Influencer CRM. Manage campaigns, track interactions, and streamline outreach effortlessly using AI." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Influencer CRM | Glitch - Manage Influencer Campaigns Effectively" />
        <meta property="og:description" content="Optimize your influencer marketing with Glitch's Influencer CRM. Manage campaigns, track interactions, and streamline outreach effortlessly using AI." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/infuencer_crm_1.webp" />
      </Helmet>
      <Header />
      <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
        <h1 style={{ fontSize: "2.5rem" }} ><br /><br />Use Our Built-In CRM <br /><br />To Easily Manage Influencers</h1>
      </section>

      <div className="container my-5 text-center">
        <h2 className='mb-3'>Watch To See The Entire Process</h2>
        <div className="ratio ratio-16x9 border border-white border-2">
          <iframe
            src="https://www.youtube.com/embed/gqyMALpUcNQ"
            allowFullScreen
            title="YouTube Video"
          ></iframe>
        </div>

        <form className="contact-form mt-4" id="contact-form" method="POST">
                <div className="form-group w-100 text-center">
                  <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Register Now</span></Link>
                </div>
              </form>
      </div>

      <section className="py-5 bg-light text-black">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 order-lg-2">
              <img src="/assets/images/publishers/crm_emails.gif" alt="AI Filter Influencers" className="img-fluid rounded shadow mb-3" />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="mb-4 text-black">AI-Driven Outreach Management</h2>
              <p className="lead">Automate your outreach emails with our AI-powered CRM. Prefer a personal touch? Customize and send your email campaigns effortlessly.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center mb-5">
             <div className="col-lg-6 order-lg-2">
              <h2 className="mb-4">Track Email Opens and Responses</h2>
              <p className="lead">Monitor email engagement with influencers by tracking opens and responses, ensuring you stay informed throughout your campaign.</p>
            </div>
            <div className="col-lg-6 order-lg-1">
              <img src="/assets/images/publishers/crm_responsed.gif" alt="Track Influencer Engagement" className="img-fluid rounded shadow border border-white border-2" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light text-black">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 order-lg-2">
              <img src="/assets/images/publishers/crm_expectations.gif" alt="Update Contract Expectations" className="img-fluid rounded shadow mb-3" />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="mb-4 text-black">Effortless Contract Updates</h2>
              <p className="lead">Seamlessly update contract terms and payment details as you negotiate with influencers, keeping everyone on the same page.</p>
              <form className="contact-form mt-4" id="contact-form" method="POST">
                <div className="form-group w-100 text-center">
                  <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Register Here</span></Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 order-lg-1">
              <img src="/assets/images/publishers/crm_stages.gif" alt="Manage Sales Stages" className="img-fluid rounded shadow mb-3 border border-white border-2" />
            </div>
            <div className="col-lg-6 order-lg-2">
              <h2 className="mb-4">Manage Sales Stages</h2>
              <p className="lead">From initial outreach to final agreement, effortlessly track each stage of your sales process with influencers.</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center mb-4">
        <h4>Learn More About Performance-Based Influencer Marketing</h4>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <Link to={Navigate.publishersAIPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
          </Link>
          <Link to={Navigate.publishersCRMPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
          </Link>
          <Link to={Navigate.publishersDatabasePage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
          </Link>
          <Link to={Navigate.publishersBenefitsPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Benefits
          </Link>
          <Link to={Navigate.publishersOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
          </Link>
          <Link to={Navigate.publishersGamificationPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGamepad} className="me-1" /> Gamification
          </Link>
          <Link to={Navigate.publishersAcquisitionPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs
          </Link>
          <Link to={Navigate.publishersPricingPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
          </Link>
        </div>
      </div>

      <section className="py-5 bg-dark text-white">
        <div className="container text-center">
          <h2 className="mb-4">Join Glitch and Revolutionize Your Marketing Strategy</h2>
          <p className="lead">Explore the benefits of performance-based influencer marketing with Glitch. Maximize your game's exposure and engage effectively with influencers.</p>
          <a href={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-primary">Get Started Now</a>
        </div>
      </section>

      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/infuencer_crm_1.webp")',
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublishersCRMPage;

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import CampaignLinksManager from '../../component/section/campaigns/campaign_links_manager';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import CampaignRateCard from '../../component/section/campaigns/campaign_rate_card';
import GameTitle from '../../component/section/titles/title_display';
import Navigate from '../../../../util/Navigate';
import Moment from 'react-moment';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Calculator from '../../../../util/Calculator';
import { faFacebook, faInstagram, faKickstarter, faReddit, faTiktok, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

const InfluencerViewCampaignInvitePage = () => {
    const [campaign, setCampaign] = useState({});
    const [influencer, setInfluencer] = useState({});
    const [current, setCurrent] = useState(null);
    const { campaign_id, influencer_id } = useParams();
    const [me, setMe] = useState({});
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [signingUp, setSigningUp] = useState(false);
    const [acceptingInvite, setAcceptingInvite] = useState(false);
    const [rejectingInvite, setRejectingInvite] = useState(false);
    const [showRateCard, setShowRateCard] = useState(false);


    // Map the numeric values to string representations for Campaign Objectives and Influencer Campaign Types
    const campaignObjectiveMap = {
        1: 'Brand Awareness',
        2: 'Audience Engagement',
        3: 'Lead Generation',
        4: 'Sales Conversion',
        5: 'Brand Identity & Reputation',
        6: 'Customer Loyalty & Retention',
        7: 'Content Amplification & Diversity',
        8: 'Market Feedback & Insight',
        9: 'Educating Audience',
        10: 'Community Building',
        11: 'Driving Web Traffic',
        12: 'SEO Benefits',
    };

    const influencerCampaignTypeMap = {
        1: 'Sponsored Content',
        2: 'Affiliate Marketing',
        3: 'Product Gifting',
        4: 'Brand Ambassador',
        5: 'Social Media Takeover',
        6: 'Contests & Giveaways',
        7: 'Event Coverage',
        8: 'Co-Creation of Products',
        9: 'Influencer Whitelisting',
        10: 'Social Issues & Cause Campaigns',
    };

    const platforms = [
        { name: 'Instagram', key: 'instagram', icon: faInstagram },
        { name: 'Twitter', key: 'twitter', icon: faTwitter },
        { name: 'Twitch', key: 'twitch', icon: faTwitch },
        { name: 'YouTube', key: 'youtube', icon: faYoutube },
        { name: 'Facebook', key: 'facebook', icon: faFacebook },
        { name: 'Kick', key: 'kick', icon: faKickstarter },
        { name: 'Reddit', key: 'reddit', icon: faReddit },
        { name: 'TikTok', key: 'tiktok', icon: faTiktok },
    ];

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (Glitch.util.Session.isLoggedIn()) {
            Glitch.api.Users.me().then(response => {
                setMe(response.data.data);
            }).catch(error => {
                console.error('Error fetching me', error);
            });

            Glitch.api.Campaigns.viewInfluencerCampaign(campaign_id, Glitch.util.Session.getID()).then(response => {
                setCurrent(response.data.data);
            }).catch(error => {
                console.error('Error fetching current campaign', error);
            });
        }

        Glitch.api.Campaigns.viewInfluencerInvite(campaign_id, influencer_id, token).then(response => {
            const updatedCampaign = {
                ...response.data.data.campaign,
                type: influencerCampaignTypeMap[response.data.data.type],
                objective: campaignObjectiveMap[response.data.data.objective],
            };

            setCampaign(updatedCampaign);
            setInfluencer(response.data.data.influencer);

        }).catch(error => {
            console.error('Error fetching campaign invite', error);
        });
    }, [campaign_id, influencer_id, location.search]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const acceptInvite = async () => {
        setAcceptingInvite(true);
        Glitch.api.Campaigns.acceptInfluencerInvite(campaign_id, me?.influencer.id).then(response => {
            navigate(Navigate.influencersManageCampaignPage(campaign.id, me.id));
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setAcceptingInvite(false);
        });
    };

    const declineInvite = async () => {
        setRejectingInvite(true);
        Glitch.api.Campaigns.declineInfluencerInvite(campaign_id, me?.influencer.id).then(response => {
            setCampaign(response.data.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setRejectingInvite(false);
        });
    };

    const register = () => {
        if (Glitch.util.Session.isLoggedIn()) {
            setSigningUp(true);
            Glitch.api.Campaigns.createInfluencerCampaign(campaign_id, me.id).then(response => {
                navigate(Navigate.influencersManageCampaignPage(response.data.data.campaign_id, response.data.data.user_id));
            }).catch(error => {
                let jsonErrors = error?.response?.data;

                if (jsonErrors) {
                    setErrors(jsonErrors);

                    setTimeout(() => {
                        setErrors({});
                    }, timeouts.error_message_timeout);
                }
            }).finally(() => {
                setSigningUp(false);
            });
        } else {
            const redirectUrl = Navigate.influencersApplyCampaignPage(campaign_id);
            const onboardingUrl = Navigate.creatorsOnboardingStep1Page();
            navigate(`${onboardingUrl}?redirect=${encodeURIComponent(redirectUrl)}`);
        }
    };

    const SocialMediaLink = ({ icon, data, platform }) => {
        if (!data[`${platform}_username`] && platform !== "youtube") {
            return null;
        }

        const formatNumber = (num) => {
            return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
        };

        const biographyField = `${platform}_biography`;
        const biography = data[biographyField];

        const hasYouTubeData = platform === "youtube" && (data.youtube_link || data.youtube_custom_url || data.youtube_title || data.youtube_subscriber_count || data.youtube_video_count);

        if (platform === "youtube" && !hasYouTubeData) {
            return null;
        }

        return (
            <div className="mb-3">
                <h6><FontAwesomeIcon icon={icon} className='text-black me-2' />
                    {platform === "youtube" ? (
                        <a href={data[`youtube_link`]} target="_blank" rel="noopener noreferrer">{data[`youtube_custom_url`] || data[`youtube_title`]}</a>
                    ) : (
                        <a href={data[`${platform}_link`]} target="_blank" rel="noopener noreferrer">{data[`${platform}_username`]}</a>
                    )}
                </h6>
                <p><strong>Followers:</strong> {formatNumber(data[`${platform}_follower_count`] || data[`youtube_subscriber_count`])}</p>
                <p><strong>Engagement:</strong> {data[`${platform}_engagement_percent`] || data[`youtube_engagement_percent`]}%</p>

            </div>
        );
    };

    const hasFollowerRequirements = () => {

        let hasRequrements = false;

        platforms.map(platform => {
            if (campaign[`${platform.key}_min_followers`] || campaign[`${platform.key}_max_followers`]) {
                hasRequrements = true;
            }
        });

        return hasRequrements;

    };
    

    const renderFollowerRequirements = () => {
        return platforms.map(platform => {
            const minFollowers = campaign[`${platform.key}_min_followers`];
            const maxFollowers = campaign[`${platform.key}_max_followers`];

            if (minFollowers || maxFollowers) {
                return (
                    <div key={platform.key} className="mb-2">
                        <strong className='text-black me-2' ><FontAwesomeIcon icon={platform.icon} /> {platform.name}</strong>
                        <p className="mb-1">
                            {minFollowers ? `Min: ${minFollowers.toLocaleString()}` : ''}
                            {minFollowers && maxFollowers ? ' / ' : ''}
                            {maxFollowers ? `Max: ${maxFollowers.toLocaleString()}` : ''}
                        </p>
                    </div>
                );
            }

            return null;
        });
    };

    const renderTargetingCriteria = () => {
        const { target_audience, countries, target_age_minimum, target_age_maximum, genders, types } = campaign;

        if (!target_audience && !countries?.length && !target_age_minimum && !target_age_maximum && !genders?.length && !types?.length) {
            return null;
        }

        return (
            <>
                <hr />
                <section className="mb-4">
                    <h3 className="text-black">Targeting Criteria</h3>
                    <p className='lead'>Below is information on the ideal audience that this campain is targeting.</p>
                    {target_audience && <><h6 className='text-black'>Target Audience:</h6><p><span dangerouslySetInnerHTML={createMarkup(target_audience)} /></p></>}
                    {countries?.length > 0 && (
                        <><h6 className='text-black'>Countries:</h6> <p>{countries.map(country => country.name).join(', ')}</p></>
                    )}
                    {(target_age_minimum || target_age_maximum) && (
                        <><h6 className='text-black'>Age Range:</h6> <p>{target_age_minimum ? target_age_minimum : 'No minimum'} - {target_age_maximum ? target_age_maximum : 'No maximum'}</p></>
                    )}
                    {genders?.length > 0 && (
                        <><h6 className='text-black'>Genders:</h6> <p> {genders.map(gender => gender.name).join(', ')}</p></>
                    )}
                    {types?.length > 0 && (
                        <><h6 className='text-black'>Game Types:</h6> <p>{types.map(type => type.name).join(', ')}</p></>
                    )}
                </section>
            </>
        );
    };


    return (
        <>
            <Helmet>
                <title>{`Campaign - ${campaign?.title?.name}`}</title>
                <meta name="description" content={`Promotion campaign for the game ${campaign?.title?.name} by ${campaign?.title?.developer}.`} />
                <meta property="og:title" content={`Campaign - ${campaign?.title?.name}`} />
                <meta property="og:description" content={`Promotion campaign for the game ${campaign?.title?.name} by ${campaign?.title?.developer}.`} />
                <meta property="og:image" content={campaign?.title?.image_main || 'https://www.glitch.fun/assets/images/logo/glitch_3.png'} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Campaign - ${campaign?.title?.name}`} />
                <meta name="twitter:description" content={`Promotion campaign for the game ${campaign?.title?.name} by ${campaign?.title?.developer}.`} />
                <meta name="twitter:image" content={campaign?.title?.image_main || 'https://www.glitch.fun/assets/images/logo/glitch_3.png'} />
            </Helmet>
            {Glitch.util.Session.isLoggedIn() ? <InfluencerHeader position={"relative"} /> : <Header position={"relative"} />}
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <div className="pageheader-thumb mb-4">
                        </div>
                        <h2 className="pageheader-title mt-3">Get Paid To Play and Promote {campaign?.title?.name}</h2>
                        <p className="lead">Learn more about {campaign?.title?.name} below and the options for getting paid to promote this game.</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="card">
                    <section className="card-body">
                        <GameTitle gameInfo={campaign?.title} />
                    </section>
                    <hr className='mb-4 mt-2' />
                    <div className="card-body text-dark text-black">
                        {influencer ? (
                            <>
                                <h3 className='text-black'>Your Estimated Earnings For Promoting</h3>
                                <p className="small">The estimated earnings is what you may earn based on the pricing in the rate card, your following size, and your engagement rate. If your potential earnings are showing as $0, make sure your social accounts are connected so we can analyze your earning potential.</p>

                                <p><strong>Spend Limit Per Influencer: </strong>{campaign.spend_limit_per_influencer ? `$${campaign.spend_limit_per_influencer} is the maximum amount you can make from this campaign.` : 'There is no cap on how much you can make for this campaign.'}</p>

                                {(() => {
                                    const flatFees = Calculator.calculateFlatFee(influencer, campaign);
                                    return (
                                        <>
                                            {Object.keys(flatFees).map((platform) => {
                                                const fee = parseFloat(flatFees[platform]);
                                                if (fee > 0) {
                                                    return (
                                                        <p key={platform}><strong>{platform.charAt(0).toUpperCase() + platform.slice(1)} Flat Fee:</strong> ${fee.toFixed(2)}</p>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </>
                                    );
                                })()}

                                {(() => {
                                    const flatFees = Calculator.calculateFlatFee(influencer, campaign);
                                    const potentialEarnings = Calculator.calculateEarningPotential(influencer, campaign);
                                    return (
                                        <>
                                            {flatFees && (flatFees?.default > 0 || flatFees?.facebook > 0 || flatFees?.youtube > 0 || flatFees?.kick > 0 || flatFees?.reddit > 0 || flatFees?.tiktok > 0 || flatFees?.twitch > 0 || flatFees?.twitter > 0 || flatFees?.instagram > 0) ? <><p><strong>Additional Earnings</strong></p></> : ''}
                                            <p><strong>Low Estimated Earnings:</strong> ${potentialEarnings.lowEarnings.toFixed(2)}</p>
                                            <p><strong>High Estimated Earnings:</strong> ${(potentialEarnings.highEarnings * 1.5).toFixed(2)}</p>

                                        </>
                                    );
                                })()}

                                <div>
                                    <button className="btn btn-primary" onClick={() => setShowRateCard(!showRateCard)}>
                                        {showRateCard ? 'Hide Rate Card' : 'Show Rate Card'}
                                    </button>
                                    {showRateCard && <CampaignRateCard campaign={campaign} user={me} />}
                                </div>


                                <div className='mt-2' >
                                    <p>This earning estimate is based on the following sizes and engagement rates of these social platforms:</p>
                                    <SocialMediaLink icon={faInstagram} data={influencer} platform="instagram" />
                                    <SocialMediaLink icon={faTiktok} data={influencer} platform="tiktok" />
                                    <SocialMediaLink icon={faYoutube} data={influencer} platform="youtube" />
                                    <SocialMediaLink icon={faTwitter} data={influencer} platform="twitter" />
                                    <SocialMediaLink icon={faFacebook} data={influencer} platform="facebook" />
                                    <SocialMediaLink icon={faReddit} data={influencer} platform="reddit" />
                                    <SocialMediaLink icon={faTwitch} data={influencer} platform="twitch" />
                                </div>
                            </>
                        ) : null}
                        <hr className='mb-4 mt-2' />
                        <section className="mb-4">
                            <h3 className="text-black mb-3">Campaign Information</h3>

                            <h6 className="text-black">Brief:</h6>
                            <p><span dangerouslySetInnerHTML={createMarkup(campaign.brief)} /></p>

                            {campaign?.deliverables && (
                                <>
                                    <h6 className="text-black">Deliverables:</h6>
                                    <p><span dangerouslySetInnerHTML={createMarkup(campaign?.deliverables)} /></p>
                                </>
                            )}
                            {campaign?.days_to_complete && (
                                <>
                                    <h6 className="text-black">Days To Complete:</h6>
                                    <p>{campaign?.days_to_complete} days</p>
                                </>
                            )}

                            <h6 className="text-black">Target Platforms For Content:</h6>
                            <ul>
                                {platforms.map(platform => (
                                    !campaign[`disable_${platform.key}`] && <li key={platform.key}>{platform.name}</li>
                                ))}
                            </ul>

                            {campaign.hashtags && <>
                                <h6 className="text-black">Hashtags:</h6>
                                <p><span dangerouslySetInnerHTML={createMarkup(campaign.hashtags)} /></p></>}
                            {campaign.highlights && <><h6 className="text-black">Highlights:</h6> <p> <span dangerouslySetInnerHTML={createMarkup(campaign.highlights)} /></p></>}
                            {campaign.prohibited_content && <> <h6 className="text-black">Prohibited Content:</h6> <p> <span dangerouslySetInnerHTML={createMarkup(campaign.prohibited_content)} /></p></>}

                            {hasFollowerRequirements() && <h6 className="text-black">Follower Requirements</h6>}

                            {renderFollowerRequirements()}


                            {campaign.start_date && <p><strong>Start Date:</strong> <Moment format="MM-DD-YYYY A">{campaign.start_date}</Moment></p>}
                            {campaign.end_date && <p><strong>End Date:</strong> <Moment format="MM-DD-YYYY A">{campaign.end_date}</Moment></p>}

                            {campaign.usage_rights ? <>
                                <>
                                    <h6 className="text-black mt-3">Content Usage Rights</h6>
                                    <p> <span dangerouslySetInnerHTML={createMarkup(campaign.usage_rights)} /></p>
                                </>

                            </> : ''}
                        </section>
                        {renderTargetingCriteria()}
                        <hr className='mb-4 mt-2' />

                        <div className="my-5">
                            <h3 className="text-black">How To Sign Up</h3>
                            <p className="lead">To sign up as an influencer and promote the game {campaign?.title?.name}, please follow these steps:</p>
                            <ol>
                                <li>Click the sign-up link below. Once you register, the campaign manager will be notified.</li>
                                <li>If the campaign is auto-approved, you'll automatically be authorized to start promoting. If it's not, a campaign manager will review your application and may ask questions, reject, or approve you.</li>
                                <li>Once approved, you'll gain access to the campaign's assets, referral links, access codes, and other information needed to promote the game.</li>
                                <li>Download the Glitch Streaming application and begin streaming and creating content. All content must be created through the app as it will track your progress, which is tied to your compensation.</li>
                                <li>After you've finished promoting, mark the campaign as complete. The brand will then have one week to review your content before distributing payment.</li>
                            </ol>
                            <div className="text-center">
                                {(errors && errors.error) ?
                                    <Danger message={errors.error} key={0} /> : ''
                                }
                                {current ? (
                                    <>
                                        <p>You have already signed up for this campaign.</p>
                                        <button className="btn btn-lg btn-primary" onClick={() => navigate(Navigate.influencersManageCampaignPage(campaign_id, me.id))}>
                                            <i className="fas fa-eye"></i> View Your Campaign
                                        </button>
                                    </>
                                ) : Glitch.util.Session.isLoggedIn() && influencer && me?.influencer?.id == influencer.id ? (
                                    <>
                                        {campaign.rejected ? <p className='text-danger'>You have rejected this campaign</p> : <p>You have been invited to this campaign.</p>}

                                        <button className="btn btn-lg btn-success me-2" onClick={acceptInvite} disabled={acceptingInvite}>
                                            {acceptingInvite && (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            )}
                                            <i className="fas fa-check"></i> Accept Invite
                                        </button>
                                        <button className="btn btn-lg btn-danger" onClick={declineInvite} disabled={rejectingInvite}>
                                            {rejectingInvite && (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            )}
                                            <i className="fas fa-times"></i> Reject Invite
                                        </button>
                                    </>
                                ) : (
                                    <button className={`btn btn-lg btn-success ${signingUp ? 'disabled' : ''}`} onClick={register} disabled={signingUp}>
                                        {signingUp && (
                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        )}
                                        {me?.id ? 'Apply Now!' : 'Sign Up'}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default InfluencerViewCampaignInvitePage;

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import Navigate from '../../../../../util/Navigate';
import { Link } from 'react-router-dom';
import MessageMessagesList from '../messages/message_messages';
import { Modal, Button } from 'react-bootstrap';

const CampaignUserManager = ({ campaignID, userID }) => {
    const [influencers, setInfluencers] = useState([]);
    const [campaign_id, setCampaignID] = useState(null);
    const [user_id, setUserID] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [thread, setThread] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredInfluencers, setFilteredInfluencers] = useState([]);
    const [confirmResend, setConfirmResend] = useState(null);

    useEffect(() => {
        setCampaignID(campaignID);
        setUserID(userID);
        fetchLinks(campaignID);
    }, [campaignID, userID]);

    useEffect(() => {
        setFilteredInfluencers(
            influencers.filter(influencer =>
                influencer.user.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                influencer.user.bio?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, influencers]);

    const fetchLinks = async (id) => {
        Glitch.api.Campaigns.listInfluencerCampaigns({ campaign_id: id }).then(response => {
            setInfluencers(response.data.data || []);
        }).catch(error => {
            console.error(error);
        });
    };

    const acceptInfluencer = async (userID) => {
        Glitch.api.Campaigns.acceptInfluencerRequest(campaign_id, userID).then(response => {
            fetchLinks(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const declineInfluencer = async (userID) => {
        Glitch.api.Campaigns.declineInfluencerRequest(campaign_id, userID).then(response => {
            fetchLinks(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const reviewInfluencer = async (userID) => {
        Glitch.api.Campaigns.reviewInfluencerRequest(campaign_id, userID).then(response => {
            fetchLinks(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const showMessages = async (userID) => {
        Glitch.api.Messages.createOrGetThread({ users: [Glitch.util.Session.getID(), userID] }).then(response => {
            setThread(response.data.data);
            setShowModal(true);
        }).catch(error => {
            console.error("Failed to create or get thread:", error);
        });
    };
    
    const sendMessage = () => {
        if (newMessage.trim() !== '') {
            Glitch.api.Messages.sendMessage({ message: newMessage, thread_id: thread.id }).then(response => {
                // Update the thread with the new message
                setThread({
                    ...thread,
                    messages: [...thread.messages, response.data.data]
                });
                setNewMessage('');
            }).catch(error => {
                console.error("Failed to send message:", error);
            });
        }
    };

    const handleResendClick = (userID) => {
        setConfirmResend(userID);
    };

    const resendAcceptance = async () => {
        if (confirmResend) {
            Glitch.api.Campaigns.resendAcceptanceEmail(campaign_id, confirmResend).then(response => {
                console.log('Resent acceptance email');
                // Disable and grey out the button
                setInfluencers(prev => prev.map(influencer => 
                    influencer.user.id === confirmResend ? { ...influencer, resendDisabled: true } : influencer
                ));
                setConfirmResend(null);
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 0: return <small><span className="badge bg-secondary small">Unapproved</span></small>;
            case 1: return <small><span className="badge bg-success small">Approved</span></small>;
            case 2: return <small><span className="badge bg-warning text-dark small" >In Review</span></small>;
            case 3: return <small><span className="badge bg-info small">Pending</span></small>;
            case 4: return <small><span className="badge bg-primary small">Require More Info</span></small>;
            case 5: return <small><span className="badge bg-danger small">Denied</span></small>;
            case 6: return <small><span className="badge bg-dark small">Banned</span></small>;
            case 7: return <small><span className="badge bg-warning text-dark small">Probation</span></small>;
            default: return <small><span className="badge bg-secondary small">Unknown</span></small>;
        }
    };

    return (
        <div className="container my-4">
            <h3>Manage Influencers</h3>
            <p>Manage the influencers who have signed up to market this game. If they have not been approved, they will not start the campaign. Use the 'Approve', 'Decline' and 'Review' buttons for creators that still require an approval.</p>
            
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search influencers by username or bio"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {filteredInfluencers.length === 0 ? (
                <div className="text-center my-4 card card-body text-black">
                    <p>No Influencers have signed up. Find and invite influencers.</p>
                    <Link to={Navigate.campaignsFindInfluencers(campaignID)} className="btn btn-primary">
                        <i className="fas fa-user-plus"></i> Invite Influencers
                    </Link>
                </div>
            ) : (
                <div className="list-group my-4">
                    {filteredInfluencers.map((influencer, index) => (
                        <div className="authors list-group-item" key={index}>
                            <div className="d-flex align-items-center">
                                <div className="author-thumb me-3">
                                    <Link to={Navigate.campaignsResearchInfluencer(campaign_id, influencer.user.id)}>
                                        <img
                                            src={influencer?.user?.avatar ? influencer?.user?.avatar : "https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png"}
                                            alt="author"
                                            className="rounded-circle"
                                            style={{ width: '100%' }}
                                        />
                                    </Link>
                                </div>
                                <div className="author-content text-white">
                                    <h6 className="mb-2">{influencer.user.username}</h6>
                                    
                                    <Link className="btn btn-info me-2 mb-2" to={Navigate.campaignsResearchInfluencer(campaign_id, influencer.user.id)}>
                                        <i className="fas fa-user"></i> Profile
                                    </Link>
                                    <Link className="btn btn-info me-2 mb-2" to={Navigate.campaignsPerformanceInfluencer(campaign_id, influencer.user.id)}>
                                        <i className="fas fa-chart-line"></i> Performance
                                    </Link>
                                    <Link className="btn btn-info me-2 mb-2" to={Navigate.campaignsUpdateInfluencer(campaign_id, influencer.user.id)}>
                                        <i className="fas fa-list"></i> Customize Rate Card
                                    </Link>
                                    <button className="btn btn-info mb-2 me-2" type="button" onClick={() => showMessages(influencer.user.id)}>
                                        <i className="fas fa-envelope"></i> Message
                                    </button>
                                    {influencer.acceptance_status === 1 && (
                                        <button
                                            className={`btn btn-info mb-2 ${influencer.resendDisabled ? 'disabled' : ''}`}
                                            type="button"
                                            onClick={() => handleResendClick(influencer.user.id)}
                                            disabled={influencer.resendDisabled}
                                        >
                                            <i className="fas fa-vote-yea"></i> Resend Acceptance
                                        </button>
                                    )}
                                    <br /><br />
                                    <p>{influencer.user.bio}</p>
                                    {influencer.acceptance_status !== 1 && (
                                        <div className='mb-3'>
                                            <p className="small"><strong>Current Status:</strong> {getStatusBadge(influencer.acceptance_status)}. Change approval status.</p>
                                            <button className="btn btn-success btn-sm me-2" onClick={() => acceptInfluencer(influencer.user.id)}>
                                                <i className="fas fa-check"></i> Accept
                                            </button>
                                            <button className="btn btn-danger btn-sm me-2" onClick={() => declineInfluencer(influencer.user.id)}>
                                                <i className="fas fa-times"></i> Decline
                                            </button>
                                            <button className="btn btn-warning btn-sm me-2" onClick={() => reviewInfluencer(influencer.user.id)}>
                                                <i className="fas fa-search"></i> Review
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Confirmation Modal */}
            <Modal show={!!confirmResend} onHide={() => setConfirmResend(null)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Confirm Resend</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>
                    Are you sure you want to resend the acceptance email?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirmResend(null)}>Cancel</Button>
                    <Button variant="primary" onClick={resendAcceptance}>Yes, Resend</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Conversation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>
                    {thread && <MessageMessagesList messages={thread.messages} users={thread.users} />}
                    <div className="mb-3">
                        <label htmlFor="newMessage" className="form-label">Reply</label>
                        <textarea className="form-control" id="newMessage" rows="3" value={newMessage} onChange={(e) => setNewMessage(e.target.value)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={sendMessage}>Send <i className="fas fa-paper-plane"></i></Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CampaignUserManager;

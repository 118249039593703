import { Component, Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitch, faGoogle, faTwitter, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

class SocialMedia extends Component {
    render() {
        const { redirect } = this.props;
        const queryParam = redirect ? `?redirect=${encodeURIComponent(redirect)}` : '';

        const authUrls = {
            facebook: `${process.env.REACT_APP_OAUTH_FACEBOOK_URL ?? 'https://api.glitch.fun/auth/facebook/redirect'}${queryParam}`,
            twitch: `${process.env.REACT_APP_OAUTH_TWITCH_URL ?? 'https://api.glitch.fun/auth/twitch/redirect'}${queryParam}`,
            twitter: `${process.env.REACT_APP_OAUTH_TWITTER_URL ?? 'https://api.glitch.fun/auth/twitter/redirect'}${queryParam}`,
            youtube: `${'https://api.glitch.fun/auth/google/redirect'}${queryParam}`,
            tiktok: `${process.env.REACT_APP_OAUTH_TIKTOK_URL ?? 'https://api.glitch.fun/auth/tiktok/redirect'}${queryParam}`
        };

        return (
            <Fragment>
                <div className="social-media-container">
                    <ul className="social-media-list">
                        <li className="social-media-item">
                            <a href={authUrls.facebook} className="social-media-link facebook">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                        </li>
                        <li className="social-media-item">
                            <a href={authUrls.twitch} className="social-media-link twitch">
                                <FontAwesomeIcon icon={faTwitch} />
                            </a>
                        </li>
                        <li className="social-media-item">
                            <a href={authUrls.twitter} className="social-media-link twitter">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </li>
                        <li className="social-media-item">
                            <a href={authUrls.youtube} className="social-media-link google">
                                <FontAwesomeIcon icon={faGoogle} />
                            </a>
                        </li>
                        <li className="social-media-item">
                            <a href={authUrls.tiktok} className="social-media-link tiktok">
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default SocialMedia;

import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Glitch from 'glitch-javascript-sdk';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Calculator from '../../../../util/Calculator';
import { Modal, Button, Form } from 'react-bootstrap';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import Wysiwyg from '../../component/form/wysiwyg';

const CampaignsFindInfluencersPage = () => {
    const [influencers, setInfluencers] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [filters, setFilters] = useState({
        first_name: '',
        location: '',
        speaking_language: '',
        instagram_username: '',
        instagram_follower_count_gt: '',
        instagram_follower_count_lt: '',
        tiktok_username: '',
        tiktok_follower_count_gt: '',
        tiktok_follower_count_lt: '',
        youtube_username: '',
        youtube_subscriber_count_gt: '',
        youtube_subscriber_count_lt: '',
        keyword: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [inviteStatus, setInviteStatus] = useState({});
    const [showCustomInviteModal, setShowCustomInviteModal] = useState(false);
    const [customEmail, setCustomEmail] = useState('');
    const [currentInfluencerId, setCurrentInfluencerId] = useState(null);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultAvatar = 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png';

    const debounceTimeoutRef = useRef(null);
    const latestFiltersRef = useRef(filters);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = parseInt(queryParams.get('page'), 10);
        
        if (page) {
            setCurrentPage(page);
        }

        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }).catch(error => {
            console.error(error);
        });

        fetchInfluencers();
    }, [currentPage]);

    const fetchInfluencers = async () => {
        setIsLoading(true);
        try {
            const response = await Glitch.api.Influencers.listInfluencers({ ...latestFiltersRef.current, page: currentPage, campaign_id: id });
            setInfluencers(response.data.data);
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.error('Error fetching influencers', error);
        } finally {
            setIsLoading(false);
            const scrollToPosition = document.body.scrollHeight * 0.03;
            window.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth'
            });
        }
    };

    const fetchEmailTemplates = async () => {
        Glitch.api.Communities.listEmailTemplates(campaign.community_id)
            .then((response) => {
                setEmailTemplates(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleTemplateChange = (e) => {
        const template_id = e.target.value;
        setSelectedTemplate(template_id);

        Glitch.api.Communities.populateEmailTemplate(campaign.community_id, template_id, {
            campaign_id: campaign.id,
            influencer_id: currentInfluencerId,
        })
            .then((response) => {
                setCustomEmail(response.data.data.html_version);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const debounceFetchInfluencers = useCallback(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(() => {
            setCurrentPage(1);
            navigate(`${location.pathname}?page=1`);
            fetchInfluencers();
        }, 2000);
    }, [navigate, location.pathname]);

    const handleInputChange = (e) => {
        const newFilters = { ...filters, [e.target.name]: e.target.value };
        setFilters(newFilters);
        latestFiltersRef.current = newFilters;
        debounceFetchInfluencers();
    };

    const handleSearch = () => {
        setCurrentPage(1);
        navigate(`${location.pathname}?page=1`);
        fetchInfluencers();
    };

    const handleClearFilters = () => {
        const initialFilters = {
            first_name: '',
            location: '',
            speaking_language: '',
            instagram_username: '',
            instagram_follower_count_gt: '',
            instagram_follower_count_lt: '',
            tiktok_username: '',
            tiktok_follower_count_gt: '',
            tiktok_follower_count_lt: '',
            youtube_username: '',
            youtube_subscriber_count_gt: '',
            youtube_subscriber_count_lt: '',
            keyword: ''
        };
        setFilters(initialFilters);
        latestFiltersRef.current = initialFilters;
        setCurrentPage(1);
        navigate(`${location.pathname}?page=1`);
        fetchInfluencers();
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        navigate(`${location.pathname}?page=${newPage}`);
    };

    const calculateAverageMetrics = (influencer) => {
        let platform_count = 0;
        let totalFollowers = 0;
        let totalEngagement = 0;

        const platforms = ['instagram', 'tiktok', 'youtube', 'reddit', 'twitter', 'facebook', 'twitch'];

        platforms.forEach(platform => {
            const followerCount = influencer[`${platform}_follower_count`] || influencer[`${platform}_subscriber_count`];
            const engagementPercent = influencer[`${platform}_engagement_percent`];

            if (followerCount > 0 && engagementPercent > 0) {
                platform_count++;
                totalFollowers += followerCount;
                totalEngagement += parseFloat(engagementPercent);
            }
        });

        const averageFollowers = platform_count > 0 ? totalFollowers / platform_count : 0;
        const averageEngagement = platform_count > 0 ? totalEngagement / platform_count : 0;

        const estimatedReach = averageFollowers * (averageEngagement / 100);
        const linkClicks = estimatedReach * (averageEngagement / 100);

        return { estimatedReach, linkClicks };
    };

    const sendInvite = async (influencer_id, custom_email = null) => {
        setIsLoading(true);
        try {
            await Glitch.api.Campaigns.sendInfluencerInvite(id, { influencer_id: influencer_id, custom_invite_email: custom_email });
            setModalMessage('Invite Sent Successfully');
            setShowModal(true);
            setInviteStatus((prevStatus) => ({
                ...prevStatus,
                [influencer_id]: 'sent'
            }));
        } catch (error) {
            if (error.response && error.response.status === 402) {
                if (subscriptions.length === 0) {
                    setErrorMessage(
                        <div className='text-center'>
                            <p>You must sign up for a subscription to send the invite. Please follow the button to select a subscription account.</p>
                            <Link to={Navigate.communitiesSubscribePage(campaign.community_id)} className='btn btn-success'>Get A Subscription</Link>
                        </div>
                    );
                } else {
                    setErrorMessage('You must update your payment information to send the invite.');
                }
                setShowErrorModal(true);
            } else {
                console.error('Error sending invite', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenCustomInviteModal = (influencer_id) => {
        setCurrentInfluencerId(influencer_id);
        fetchEmailTemplates();
        setShowCustomInviteModal(true);
    };

    const handleSendCustomInvite = () => {
        sendInvite(currentInfluencerId, customEmail);
        setShowCustomInviteModal(false);
        setCustomEmail('');
    };

    const handleCloseModal = () => setShowModal(false);

    const renderPaginationLinks = () => {
        const links = [];
        const start = currentPage - 5 > 0 ? currentPage - 5 : 1;
        const end = showMore ? Math.min(currentPage + 10, totalPages) : Math.min(start + 9, totalPages);

        for (let i = start; i <= end; i++) {
            links.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
                </li>
            );
        }

        if (end < totalPages) {
            links.push(
                <li key="show-more" className="page-item">
                    <button className="page-link" onClick={() => setShowMore(true)}>Show More</button>
                </li>
            );
        }

        return links;
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <div className="container mt-4">
                    <Breadcrumbs items={[
                        { name: 'Campaigns', link: Navigate.campaignsPage() },
                        { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                        { name: 'Find Influencers', link: Navigate.campaignsFindInfluencers(id) }]}
                    />

                    <h2><FontAwesomeIcon icon={faUsers} /> Search Influencers</h2>
                    <p className='lead'>Search for the right influencer(s) for your campaign.</p>
                </div>
                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>
                <div className="container mt-4">

                    <Form>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <Form.Group controlId="formKeyword">
                                    <Form.Label>Keywords</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="keyword"
                                        value={filters.keyword}
                                        onChange={handleInputChange}
                                        placeholder="Enter keyword"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={filters.first_name}
                                        onChange={handleInputChange}
                                        placeholder="Enter first name"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formSpeakingLanguage">
                                    <Form.Label>Speaking Language</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="speaking_language"
                                        value={filters.speaking_language}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select language</option>
                                        <option value="english">English</option>
                                        <option value="spanish">Spanish</option>
                                        <option value="french">French</option>
                                        <option value="german">German</option>
                                        <option value="chinese">Chinese</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formInstagramUsername">
                                    <Form.Label>Instagram Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="instagram_username"
                                        value={filters.instagram_username}
                                        onChange={handleInputChange}
                                        placeholder="Enter Instagram username"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formInstagramFollowerCountGT">
                                    <Form.Label>Instagram Followers (Greater Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="instagram_follower_count_gt"
                                        value={filters.instagram_follower_count_gt}
                                        onChange={handleInputChange}
                                        placeholder="Enter minimum followers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formInstagramFollowerCountLT">
                                    <Form.Label>Instagram Followers (Less Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="instagram_follower_count_lt"
                                        value={filters.instagram_follower_count_lt}
                                        onChange={handleInputChange}
                                        placeholder="Enter maximum followers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formTiktokUsername">
                                    <Form.Label>TikTok Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tiktok_username"
                                        value={filters.tiktok_username}
                                        onChange={handleInputChange}
                                        placeholder="Enter TikTok username"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formTiktokFollowerCountGT">
                                    <Form.Label>TikTok Followers (Greater Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="tiktok_follower_count_gt"
                                        value={filters.tiktok_follower_count_gt}
                                        onChange={handleInputChange}
                                        placeholder="Enter minimum followers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formTiktokFollowerCountLT">
                                    <Form.Label>TikTok Followers (Less Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="tiktok_follower_count_lt"
                                        value={filters.tiktok_follower_count_lt}
                                        onChange={handleInputChange}
                                        placeholder="Enter maximum followers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formYoutubeUsername">
                                    <Form.Label>YouTube Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="youtube_username"
                                        value={filters.youtube_username}
                                        onChange={handleInputChange}
                                        placeholder="Enter YouTube username"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formYoutubeSubscriberCountGT">
                                    <Form.Label>YouTube Subscribers (Greater Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="youtube_subscriber_count_gt"
                                        value={filters.youtube_subscriber_count_gt}
                                        onChange={handleInputChange}
                                        placeholder="Enter minimum subscribers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formYoutubeSubscriberCountLT">
                                    <Form.Label>YouTube Subscribers (Less Than)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="youtube_subscriber_count_lt"
                                        value={filters.youtube_subscriber_count_lt}
                                        onChange={handleInputChange}
                                        placeholder="Enter maximum subscribers"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formLocation">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        value={filters.location}
                                        onChange={handleInputChange}
                                        placeholder="Enter location"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-12 text-right mt-3">
                                <Button variant="primary" onClick={handleSearch}>
                                    <FontAwesomeIcon icon={faSearch} /> Search
                                </Button>
                                <Button variant="secondary" className="ms-2" onClick={handleClearFilters}>
                                    <FontAwesomeIcon icon={faTimes} /> Clear
                                </Button>
                            </div>
                        </div>
                    </Form>
                    <hr />
                    <h3><FontAwesomeIcon icon={faUsers} /> Influencers</h3>

                    <Link  className='btn btn-info' to={Navigate.campaignsInviteInfluencer(id)}><i className="fas fa-plus-circle"></i> Manually Invite Influencer</Link>
                    <p className='small'>If you don't see an influencer in our database but have their contact information, you can manually invite them.</p>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {influencers.map(influencer => (
                            <div key={influencer.id} className="card mb-3 w-100">
                                <div className="row g-0">
                                    <div className="col-md-3 pt-2">
                                        <img src={getInfluencerImage(influencer)} style={{ width: '100%' }} className="img-fluid rounded-start" alt={influencer.first_name} />
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card-body">
                                            <h4 className="card-title">{influencer.first_name || influencer.instagram_username || influencer.youtube_title}</h4>
                                            <div>
                                                {['instagram', 'tiktok', 'youtube', 'twitch', 'twitter', 'reddit', 'facebook'].map(platform => {
                                                    const followers = influencer[`${platform}_follower_count`] || influencer[`${platform}_subscriber_count`];
                                                    const engagement = influencer[`${platform}_engagement_percent`];
                                                    const platformLink = influencer[`${platform}_link`];

                                                    return followers > 0 && (
                                                        <div key={platform}>
                                                            <h5><a href={platformLink} target="_blank" rel="noopener noreferrer">{platform.toUpperCase()}</a></h5>
                                                            <p><strong>Followers:</strong> {followers.toLocaleString()}</p>
                                                            <p><strong>Engagement:</strong> {engagement}%</p>
                                                            <hr />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {(() => {
                                                const { estimatedReach, linkClicks } = calculateAverageMetrics(influencer);
                                                return (
                                                    <>
                                                        <h6 className='text-black'>Estimated Results For Your Game</h6>
                                                        <p><strong>Estimated Reach:</strong> {Math.floor(estimatedReach).toLocaleString()}</p>
                                                        <p><strong>Estimated Link Clicks:</strong> {Math.floor(linkClicks).toLocaleString()}</p>
                                                    </>
                                                );
                                            })()}
                                            <hr />
                                            <h6 className='text-black'>Estimated Payout</h6>
                                            <p>The estimated payout is what you may potentially pay the influencer based on the pricing in your rate card, the influencers following size, and the influencers engagement rate.</p>
                                            
                                            {(() => {
                                                const flatFees = Calculator.calculateFlatFee(influencer, campaign);
                                                return (
                                                    <>
                                                        {Object.keys(flatFees).map((platform) => {
                                                            const fee = parseFloat(flatFees[platform]);
                                                            if (fee > 0) {
                                                                return (
                                                                    <p key={platform}><strong>{platform.charAt(0).toUpperCase() + platform.slice(1)} Flat Fee:</strong> ${fee.toFixed(2)}</p>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </>
                                                );
                                            })()}

                                            {(() => {
                                                const flatFees = Calculator.calculateFlatFee(influencer, campaign);
                                                const potentialEarnings = Calculator.calculateEarningPotential(influencer, campaign);
                                                return (
                                                    <>
                                                        {flatFees && (flatFees?.default > 0 || flatFees?.facebook > 0 || flatFees?.youtube > 0 || flatFees?.kick > 0 || flatFees?.reddit > 0 || flatFees?.tiktok > 0 || flatFees?.twitch > 0 || flatFees?.twitter > 0 || flatFees?.instagram > 0 ) ? <><strong>Additional Earnings</strong><br /></> : ''}
                                                        <p><strong>Low Estimated Earnings:</strong> ${potentialEarnings.lowEarnings.toFixed(2)}</p>
                                                        <p><strong>High Estimated Earnings:</strong> ${potentialEarnings.highEarnings.toFixed(2)}</p>
                                                    </>
                                                );
                                            })()}

                                            {influencer.invite ? (
                                                <button type="button" className="btn btn-secondary" disabled>
                                                    <i className="fas fa-paper-plane"></i> Invited on {new Date(influencer.invite.invite_created_at).toLocaleDateString()}
                                                </button>
                                            ) : (
                                                <>
                                                    <button type="button" className="btn btn-success" onClick={() => sendInvite(influencer.id)} disabled={isLoading || inviteStatus[influencer.id] === 'sent'}>
                                                        <i className="fas fa-paper-plane"></i>  {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : inviteStatus[influencer.id] === 'sent' ? 'Invite Just Sent' : 'Invite'}
                                                    </button>
                                                    <button type="button" className="btn btn-success ms-2" onClick={() => handleOpenCustomInviteModal(influencer.id)} disabled={isLoading || inviteStatus[influencer.id] === 'sent'}>
                                                        <i className="fas fa-envelope"></i> Send Custom Invite
                                                    </button>
                                                </>
                                            )}
                                            <Link to={Navigate.campaignsViewInfluencer(id, influencer.id)} type="button" className="btn btn-primary ms-2"><i className="fas fa-eye"></i> View More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            {renderPaginationLinks()}
                        </ul>
                    </nav>
                </div>
            </Fragment>

            {/* Success Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Error Modal */}
            <div className={`modal fade ${showErrorModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showErrorModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content text-black">
                        <div className="modal-header">
                            <h5 className="modal-title text-black">{subscriptions.length === 0 ? 'Sign Up for a Subscription' : 'Update Payment Information'}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowErrorModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{errorMessage}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => setShowErrorModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Custom Invite Modal */}
            <Modal show={showCustomInviteModal} onHide={() => setShowCustomInviteModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Custom Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="customEmail" className='text-black'>
                        <p className='text-black'>A custom invite allows you to personalize the email being sent to the influencer. Please write your custom email below.</p>

                        <Form.Label>Select Email Template (optional)</Form.Label>
                        <Form.Select onChange={handleTemplateChange} value={selectedTemplate || ''}>
                            <option value="">-- Select Template --</option>
                                {emailTemplates.map((template) => (
                                    <option key={template.id} value={template.id}>
                                        {template.name}
                                    </option>
                                                ))}
                            </Form.Select>
                            <p className='small'>If you have written custom email templates, you can enter them in the message box below. Custom email templates can modified <Link to={Navigate.communitiesEmailTemplatesPage(campaign.community_id)}>here</Link>.</p>
                                       
                        <Form.Label>Custom Email Template</Form.Label>
                        <Wysiwyg id="custom_email" name="custom_email" onChange={(content) => {setCustomEmail(content)}} placeholder="Enter custom email template">{customEmail || ''}</Wysiwyg>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCustomInviteModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleSendCustomInvite}>Send Invite</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CampaignsFindInfluencersPage;

import Glitch from 'glitch-javascript-sdk';
import { Component, Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PageHeader from '../../component/layout/pageheader';
import withRouter from '../../../../util/withRouter';
import Roles from '../../../../constants/roles';
import Danger from '../../component/alerts/Danger';
import Loading from '../../component/alerts/Loading';
import SidebarManageMenu from '../../component/section/communities/menu_side_manage';
import PublisherHeader from '../../component/layout/publisherheader';

class CommunityContractsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            community: {},
            users: [],
            super_administrators: [],
            administrators: [],
        };
    }

    OAuthLinks = {
        docusign: `${window.location.origin}${Navigate.authDocusign()}?redirect=${window.location.origin}${Navigate.authDocusignComplete()}&community_id=${this.props.router.params.id}`,
        simplesign: `${window.location.origin}${Navigate.authSimpleSign()}?redirect=${window.location.origin}${Navigate.authSimpleSignComplete()}&community_id=${this.props.router.params.id}`,

    };

    handleOAuthLogin = (platform) => {
        window.open(this.OAuthLinks[platform.toLowerCase()], 'OAuthWindow', 'height=600,width=400');
    }

    clearDocusign = () => {

        Glitch.api.Communities.clearDocusignAuth(this.state.community.id).then(response => {
            this.loadCommunity(this.props.router.params.id)
        }).catch(error => {

        });

        Glitch.api.Users.clearDocusignAuth().then(response => {

        }).catch(error => {

        });

    }

    loadCommunity(id) {

        Glitch.api.Communities.view(id).then(response => {

            this.setState({ community: response.data.data });

        }).catch(error => {

        })

    }


    componentDidMount() {

        let id = this.props.router.params.id;

        this.loadCommunity(id);

        Glitch.api.Communities.listUsers(id).then(response => {
            this.setState({ users: response.data.data });
        });

    }

    render() {
        return (
            <>
                <Fragment>
                    <PublisherHeader />
                    <PageHeader title={this.state.community.name + ' Users'} curPage={'Manage Account'} />


                    <div className="blog-section blog-single padding-top padding-bottom aside-bg">

                        <div className="container">

                            <div className="section-wrapper">
                                <div className="row justify-content-center pb-15">
                                    <div className="col-lg-8 col-12 pe-5 mt-2">


                                        <h3>Organization Contract Managemet</h3>
                                        <p className='lead'>While the contract between you and the influencer is included in our terms of service, you can also manage custom contracts and integrate with third-party document signing services. Manage the third-party service authentication below.</p>

                                        <h5>DocuSign</h5>

                                        <p>Connect your Docusign account to send contracts with Docusign.</p>
                                        <div className="mb-3">
                                            {this.state.community.docusign_auth_token ? (
                                                <button className={`btn btn-danger`} onClick={() => this.clearDocusign()}>
                                                    Clear Docusign Authentication
                                                </button>
                                            ) : (
                                                <button className={`btn btn-info`} onClick={() => this.handleOAuthLogin('docusign')}>
                                                    Authenticate Docusign
                                                </button>
                                            )}

                                        </div>


                                    </div>
                                    <div className="col-lg-4 col-md-7 col-12">
                                        <aside className="ps-lg-4">
                                            <SidebarManageMenu community_id={this.state.community.id} />

                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default withRouter(CommunityContractsPage);
import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faGamepad, faBalanceScale, faMoneyBillWave, faRocket } from '@fortawesome/free-solid-svg-icons';
import CreatorGettingStartedFull from '../../component/section/creators/creator_getting_started_full';
import Header from '../../component/layout/header';
import Navigate from '../../../../util/Navigate';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import { Helmet } from 'react-helmet';

const CreatorsGettingStarted = () => {

  const navigate = useNavigate();
  const currentUrl = window.location.href;

  return (
    <>
      <Helmet>
        <title>How to Get Started with Glitch | Earn by Playing Games</title>
        <meta name="description" content="Learn how to get started with Glitch, apply to a game, and start earning by playing and promoting games. Follow the steps to become a successful game influencer." />
        <meta name="keywords" content="Glitch, game influencer, get started, apply to game, streaming, earning by playing games" />
        <meta property="og:title" content="How to Get Started with Glitch | Earn by Playing Games" />
        <meta property="og:description" content="Learn how to get started with Glitch, apply to a game, and start earning by playing and promoting games. Follow the steps to become a successful game influencer." />
        <meta property="og:image" content="/assets/images/creators/community_1.webp" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Get Started with Glitch | Earn by Playing Games" />
        <meta name="twitter:description" content="Learn how to get started with Glitch, apply to a game, and start earning by playing and promoting games. Follow the steps to become a successful game influencer." />
        <meta name="twitter:image" content="/assets/images/creators/community_1.webp" />
      </Helmet>
      <Fragment>
        <Header position="relative" />
        <section className="pageheader-section-min">
          <div className="container mt-2">

            <div className="section-wrapper text-center">
              <div className="pageheader-thumb mb-4"></div>
              <h2 className="pageheader-title">How To Get Started With Glitch</h2>
              <p className="lead">Learn how to apply to a game and start earning.</p>
            </div>
          </div>
        </section>

        <div className="container my-5">
          <p className="lead text-center">If you are interested in getting paid to play and promote a game, watch the video below and also read the instructions.</p>


          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/5vtNK1H3RG8"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>


          <hr />

          <CreatorGettingStartedFull />

          <hr />

          <div className='text-center mt-3'>
            <button type='button' onClick={(e) => { navigate(Navigate.creatorsOnboardingStep1Page()) }} className='btn btn-success btn-lg'>Get Started</button>

          </div>

        </div>

        <Footer />
      </Fragment>
    </>
  );
};

export default CreatorsGettingStarted;

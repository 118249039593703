import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import Navigate from '../../../../../util/Navigate';
import { Link } from 'react-router-dom';
import MessageMessagesList from '../messages/message_messages';
import { Modal, Button, Spinner, Alert, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import { getInfluencerImage } from '../../../../../util/InfluencerUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEdit, faSave, faEye, faCheckCircle, faExclamationTriangle, faTimes, faStickyNote, faFilter } from '@fortawesome/free-solid-svg-icons';
import Wysiwyg from '../../form/wysiwyg';
import Textarea from '../../form/textarea';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

const CampaignInviteManager = ({ campaignID }) => {
    const [invites, setInvites] = useState([]);
    const [campaign_id, setCampaignID] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [thread, setThread] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [newNote, setNewNote] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredInvites, setFilteredInvites] = useState([]);
    const [inviteStatus, setInviteStatus] = useState({});
    const [editingEmail, setEditingEmail] = useState(null);
    const [viewingEmail, setViewingEmail] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState([]);
    const [noteError, setNoteError] = useState(null);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [selectedStage, setSelectedStage] = useState('All');

    const stages = ['Contacting', 'Negotiating', 'Contracting', 'Finalizing', 'Finished'];

    useEffect(() => {
        setCampaignID(campaignID);
        fetchInvites(campaignID);
    }, [campaignID]);

    useEffect(() => {
        setFilteredInvites(
            invites.filter(invite =>
                (invite.influencer.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    invite.influencer.instagram_biography?.toLowerCase().includes(searchTerm.toLowerCase())) &&
                (selectedStage === 'All' || invite.stage.toLowerCase() === selectedStage.toLowerCase())
            )
        );
    }, [searchTerm, invites, selectedStage]);

    const fetchInvites = async (id) => {
        setLoading(true);
        Glitch.api.Campaigns.listInfluencerInvites(id).then(response => {
            setInvites(response.data.data || []);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const acceptInfluencer = async (influencerID) => {
        Glitch.api.Campaigns.acceptInfluencerInvite(campaign_id, influencerID).then(response => {
            fetchInvites(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const declineInfluencerInvite = async (influencerID) => {
        Glitch.api.Campaigns.declineInfluencerInvite(campaign_id, influencerID).then(response => {
            fetchInvites(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const withdrawInfluencer = async (influencerID) => {
        Glitch.api.Campaigns.withdrawInfluencerInvite(campaign_id, influencerID).then(response => {
            fetchInvites(campaign_id);
        }).catch(error => {
            console.error(error);
        });
    };

    const updateInvite = async (influencerID, data) => {
        setSaving(true);
        try {
            await Glitch.api.Campaigns.updateInfluencerInvite(campaign_id, influencerID, data);
            setInvites(prevInvites =>
                prevInvites.map(invite =>
                    invite.influencer_id === influencerID
                        ? { ...invite, ...data }
                        : invite
                )
            );
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const getNotes = async (influencerID) => {
        try {
            const response = await Glitch.api.Influencers.listNotes(influencerID, { campaign_id });
            setNotes(response.data.data || []);
        } catch (error) {
            console.error(error);
        }
    };

    const createNote = async (influencerID, note) => {
        setNoteError(null);
        try {
            const data = {
                note: note,
                campaign_id: campaign_id,
            };
            await Glitch.api.Influencers.createNote(influencerID, data);
            setShowNotesModal(false);
            setNewNote('');
        } catch (error) {
            console.error(error);
            setNoteError('Failed to submit the note. Please try again.');
        }
    };

    const finishInfluencer = async (influencerID) => {
        if (window.confirm("Are you sure you want to mark this campaign as finished? This will stop all email outreach.")) {
            Glitch.api.Campaigns.finishInfluencerInvite(campaign_id, influencerID).then(response => {
                setInvites(prevInvites =>
                    prevInvites.map(invite =>
                        invite.influencer_id === influencerID
                            ? { ...invite, finished: true }
                            : invite
                    )
                );
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const showMessages = async (userID) => {
        Glitch.api.Messages.createOrGetThread({ users: [Glitch.util.Session.getID(), userID] }).then(response => {
            setThread(response.data.data);
            setShowModal(true);
        }).catch(error => {
            console.error("Failed to create or get thread:", error);
        });
    };

    const sendMessage = () => {
        if (newMessage.trim() !== '') {
            Glitch.api.Messages.sendMessage({ message: newMessage, thread_id: thread.id }).then(response => {
                setThread({
                    ...thread,
                    messages: [...thread.messages, response.data.data]
                });
                setNewMessage('');
            }).catch(error => {
                console.error("Failed to send message:", error);
            });
        }
    };

    const getStatusBadge = (invite) => {
        if (invite.accepted === true) return <small><span className="badge bg-success small">Accepted</span></small>;
        if (invite.rejected === true) return <small><span className="badge bg-danger small">Declined</span></small>;
        return <small><span className="badge bg-warning small">Pending</span></small>;
    };

    const toggleEditingEmail = (inviteIndex, emailIndex) => {
        setEditingEmail(editingEmail === `${inviteIndex}-${emailIndex}` ? null : `${inviteIndex}-${emailIndex}`);
    };

    const toggleViewingEmail = (inviteIndex, emailIndex) => {
        setViewingEmail(viewingEmail === `${inviteIndex}-${emailIndex}` ? null : `${inviteIndex}-${emailIndex}`);
    };

    const handleSaveEmail = (invite, emailIndex) => {
        const data = {
            [`email_touch_point_${emailIndex + 1}_html`]: invite.email_touch_points[emailIndex].html,
            [`email_touch_point_${emailIndex + 1}_text`]: invite.email_touch_points[emailIndex].text,
        };
        updateInvite(invite.influencer_id, data);
        setEditingEmail(null);
    };

    const handleWysiwygChange = (inviteIndex, emailIndex, value) => {
        const updatedInvites = [...invites];
        updatedInvites[inviteIndex].email_touch_points[emailIndex].html = value;
        setInvites(updatedInvites);
    };

    const handleTextChange = (inviteIndex, emailIndex, value) => {
        const updatedInvites = [...invites];
        updatedInvites[inviteIndex].email_touch_points[emailIndex].text = value;
        setInvites(updatedInvites);
    };

    const sanitizeEmailHtml = (html) => {
        return sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.filter(tag => tag !== 'html' && tag !== 'body' && tag !== 'footer'),
            allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                a: ['href', 'name', 'target', 'class'],
                img: ['src', 'alt', 'class']
            }
        });
    };

    const handleShowNotes = async (influencer) => {
        setSelectedInfluencer(influencer);
        await getNotes(influencer.id);
        setShowNotesModal(true);
    };

    const handleNewNoteChange = (value) => {
        setNewNote(value);
    };

    const handleSaveNote = async () => {
        if (selectedInfluencer) {
            await createNote(selectedInfluencer.id, newNote);
        }
    };

    const handleStageChange = (influencerID, newStage) => {
        updateInvite(influencerID, { stage: newStage });
    };

    const getStageCounts = () => {
        const stageCounts = {
            All: invites.length,
            Contacting: 0,
            Negotiating: 0,
            Contracting: 0,
            Finalizing: 0,
            Finished: 0
        };

        invites.forEach(invite => {
            const stage = invite.stage.charAt(0).toUpperCase() + invite.stage.slice(1).toLowerCase();
            if (stageCounts[stage] !== undefined) {
                stageCounts[stage]++;
            }
        });

        return stageCounts;
    };

    const stageCounts = getStageCounts();

    return (
        <div className="container my-4">
            <h2>Manage Invites</h2>
            <p>Manage the influencers who have been invited to market this game.</p>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search influencers by username or bio"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="mb-3">
                <div className="d-flex flex-wrap">
                    {['All', ...stages].map(stage => (
                        <Badge
                            key={stage}
                            className={`me-2 mb-2 ${selectedStage === stage ? 'bg-primary' : 'bg-secondary'}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSelectedStage(stage)}
                        >
                            {stage} <span className="badge bg-light text-dark ms-1">{stageCounts[stage]}</span>
                        </Badge>
                    ))}
                </div>
            </div>

            {loading ? (
                <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                filteredInvites.length === 0 ? (
                    <div className="text-center my-4 card card-body text-black">
                        <p>No invites have been sent. Find and invite influencers.</p>
                        <Link to={Navigate.campaignsFindInfluencers(campaignID)} className="btn btn-primary">
                            <i className="fas fa-user-plus"></i> Invite Influencers
                        </Link>
                    </div>
                ) : (
                    <div className="list-group my-4">
                        {filteredInvites.map((invite, inviteIndex) => (
                            <div className="authors list-group-item text-white" key={inviteIndex} style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-md-3 col-12 text-center text-md-start">
                                        <Link to={Navigate.campaignsViewInfluencerInvite(campaign_id, invite.influencer.id)}>
                                            <img
                                                src={getInfluencerImage(invite.influencer)}
                                                alt="author"
                                                className="rounded-circle"
                                                style={{ width: '100%' }}
                                            />
                                        </Link>
                                        <div className='text-center'><strong>Status:</strong> {getStatusBadge(invite)}</div>
                                    </div>
                                    <div className="col-md-9 col-12">
                                        <div className="d-md-flex justify-content-between align-items-start">
                                            <div className="mb-2">
                                                <h6 className="mb-2">{invite.influencer.first_name || invite.influencer.instagram_username || invite.influencer.youtube_title}</h6>
                                                <Link className="btn btn-info me-2 btn-sm" to={Navigate.campaignsViewInfluencerInvite(campaign_id, invite.influencer.id)}>
                                                    <i className="fas fa-user"></i> Profile
                                                </Link>
                                                {!invite.accepted && !invite.rejected && (
                                                    <>
                                                        <Link className="btn btn-success me-2 btn-sm" to={Navigate.campaignsUpdateInfluencerInviteCompensation(campaign_id, invite.influencer.id)}>
                                                            <i className="fas fa-money-bill-wave"></i> Update Scope
                                                        </Link>
                                                        <Link className="btn btn-primary me-2 btn-sm" to={Navigate.campaignsUpdateInfluencerInviteContract(campaign_id, invite.influencer.id)}>
                                                            <i className="fas fa-file-contract"></i> Contract
                                                        </Link>
                                                    </>
                                                )}
                                                <button className="btn btn-secondary btn-sm me-2" onClick={() => handleShowNotes(invite.influencer)}>
                                                    <FontAwesomeIcon icon={faStickyNote} /> Notes
                                                </button>
                                            </div>
                                            <div className="mb-2">
                                                <h6>Reachout Status: {invite.finished ? <span className="badge bg-secondary">Finished</span> : <span className="badge bg-primary">Ongoing</span>}</h6>
                                                {!invite.finished && (
                                                    <button className="btn btn-warning btn-sm" onClick={() => finishInfluencer(invite.influencer_id)}>
                                                        <FontAwesomeIcon icon={faCheckCircle} /> Finish Campaign
                                                    </button>
                                                )}
                                            </div>
                                            <div className="ms-0">
                                                <h6>Current Stage</h6>
                                                <DropdownButton
                                                    id={`stage-dropdown-${invite.influencer_id}`}
                                                    title={invite.stage.charAt(0).toUpperCase() + invite.stage.slice(1)}
                                                    onSelect={(e) => handleStageChange(invite.influencer_id, e)}
                                                    size="sm"
                                                >
                                                    {stages.map(stage => (
                                                        <Dropdown.Item key={stage} eventKey={stage.toLowerCase()}>
                                                            {stage}
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </div>
                                        </div>
                                        {(invite.influencer.instagram_biography || invite.influencer.youtube_description || invite.influencer.tiktok_biography || invite.influencer.twitter_biography) ? <h6 className="mt-2">Bio</h6> : ''}
                                        <p className="mt-2">{invite.influencer.instagram_biography || invite.influencer.youtube_description || invite.influencer.tiktok_biography || invite.influencer.twitter_biography}</p>

                                        <div className="email-touch-points mt-3">
                                            <h6>Reachout Management</h6>
                                            <p className='small'>We will attempt to contact the influencer by sending one email every 2-3 days, for a total of 5 emails. See the status of each email and manage what is being sent.</p>
                                            {invite.email_touch_points.map((email, emailIndex) => (
                                                <div key={emailIndex} className="email-touch-point mb-3">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>Email {emailIndex + 1}</span>
                                                        <span>Status: {email.opened ? 'Opened' : 'Not Opened'}</span>
                                                        <span>Sent At: {email.sent_at ? moment(email.sent_at).format('MMMM Do YYYY, h:mm:ss a') : 'Not Sent'}</span>
                                                        <span>Responded: {email.responded ? 'Yes' : 'No'}</span>
                                                        {email.sent_at ? (
                                                            <Button className="btn btn-secondary btn-sm" onClick={() => toggleViewingEmail(inviteIndex, emailIndex)}>
                                                                <FontAwesomeIcon icon={faEye} /> View Email
                                                            </Button>
                                                        ) : (
                                                            <Button className="btn btn-secondary btn-sm" onClick={() => toggleEditingEmail(inviteIndex, emailIndex)}>
                                                                <FontAwesomeIcon icon={faEdit} /> Edit Email
                                                            </Button>
                                                        )}
                                                    </div>
                                                    {viewingEmail === `${inviteIndex}-${emailIndex}` && (
                                                        <div className="email-view mt-3">
                                                            <h6>Email Content</h6>
                                                            <div dangerouslySetInnerHTML={{ __html: sanitizeEmailHtml(invite.email_touch_points[emailIndex].html) }} />
                                                        </div>
                                                    )}
                                                    {editingEmail === `${inviteIndex}-${emailIndex}` && (
                                                        <div className="email-edit-fields mt-3">
                                                            <h6>Edit The HTML Version Of The Email</h6>
                                                            <Wysiwyg
                                                                name={`email_touch_point_${emailIndex + 1}_html`}
                                                                value={invite.email_touch_points[emailIndex].html || ''}
                                                                onChange={(value) => handleWysiwygChange(inviteIndex, emailIndex, value)}
                                                                placeholder="Edit HTML content"
                                                            >{invite.email_touch_points[emailIndex].html}</Wysiwyg>
                                                            <h6>Edit The Text Version Of The Email</h6>
                                                            <Textarea
                                                                value={invite.email_touch_points[emailIndex].text || ''}
                                                                onChange={(e) => handleTextChange(inviteIndex, emailIndex, e.target.value)}
                                                                placeholder="Edit text content"
                                                            >{invite.email_touch_points[emailIndex].text || ''}</Textarea>
                                                            <Button className="btn btn-primary btn-sm mt-2" onClick={() => handleSaveEmail(invite, emailIndex)}>
                                                                {saving ? (
                                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                                ) : (
                                                                    <>
                                                                        <FontAwesomeIcon icon={faSave} /> Save
                                                                    </>
                                                                )}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Conversation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>
                    {thread && <MessageMessagesList messages={thread.messages} users={thread.users} />}
                    <div className="mb-3">
                        <label htmlFor="newMessage" className="form-label">Reply</label>
                        <textarea className="form-control" id="newMessage" rows="3" value={newMessage} onChange={(e) => setNewMessage(e.target.value)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={sendMessage}>Send <i className="fas fa-paper-plane"></i></Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Notes for {selectedInfluencer?.first_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>
                    {notes.length === 0 ? (
                        <p>No notes available.</p>
                    ) : (
                        <ul className="list-group">
                            {notes.map((note, index) => (
                                <li key={index} className="list-group-item">
                                    <p dangerouslySetInnerHTML={{ __html: sanitizeEmailHtml(note.note) }}></p>
                                    <small className="text-muted">Created at: {moment(note.created_at).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="mt-3">
                        <h6>New Note</h6>
                        <Wysiwyg
                            value={newNote}
                            onChange={handleNewNoteChange}
                            placeholder="Write a new note"
                        >{newNote}</Wysiwyg>
                        {noteError && <Alert variant="danger" className="mt-2">{noteError}</Alert>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowNotesModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveNote}>
                        {saving ? <FontAwesomeIcon icon={faSpinner} spin /> : <>Save Note</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CampaignInviteManager;

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import CampaignRateCard from '../../component/section/campaigns/campaign_rate_card';
import GameTitle from '../../component/section/titles/title_display';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';
import Danger from '../../component/alerts/Danger';
import CampaignPaymentForm from '../../component/section/campaigns/campaign_payment';
import Breadcrumbs from '../../component/layout/breadcrumb';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import RequiredAsterisk from '../../component/form/required_asterisk';
import Wysiwyg from '../../component/form/wysiwyg';
import { Accordion, Form } from 'react-bootstrap';

const CampaignUpdateInfluencerPage = () => {
    const [campaign, setCampaign] = useState({});
    const [user, setUser] = useState({});
    const { id, influencer_id } = useParams();
    const [me, setMe] = useState({});
    const [errors, setErrors] = useState({});
    const [paymentData, setPaymentData] = useState({});
    const [maxSpend, setMaxSpend] = useState('');

    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }
        
        if (Glitch.util.Session.isLoggedIn()) {
            Glitch.api.Users.me().then(response => {
                setMe(response.data.data);
            }).catch(error => {
                console.error('Error fetching me', error);
            });
        }

        Glitch.api.Users.profile(influencer_id).then(response => {
            setUser(response.data.data);
        }).catch(error => {
            console.error('Error fetching influencer', error);
        });

        Glitch.api.Campaigns.viewInfluencerCampaign(id, influencer_id).then(response => {
            setCampaign(response.data.data.campaign);
            setPaymentData(response.data.data);
            setMaxSpend(response.data.data.max_spend);
        }).catch(error => {
            console.error('Error fetching campaign', error);
        });
    }, [id, influencer_id]);

    const handleInputChange = (e) => {
        setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
    };

    const handleWysiwigInputChange = (name, value) => {
        setPaymentData(paymentData => ({ ...paymentData, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPaymentData(prevInvite => ({
            ...prevInvite,
            [name]: checked,
        }));
    };


    function createInputField(name, label, description, type = 'text', required = false, errors) {
        return (
            <>
                <div className="mb-3 text-start">
                    <label className='fw-bold fw-bolder ' htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>
                    <p className="small">{description}</p>
                    <input type={type} className="form-control" name={name} value={paymentData[name] || ''} onChange={handleInputChange} />
                </div>
                {errors && errors[name] && errors[name].map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </>
        );
    }

    function createTextAreaField(name, label, description, errors, required = false) {
        return (
            <>
                <div className="mb-3 text-start">
                    <label htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>
                    <p className="small">{description}</p>
                    <Wysiwyg children={paymentData[name] || ''} name={name} id={name} onChange={(value) => { handleWysiwigInputChange(name, value) }} />
                </div>
                {errors && errors[name] && errors[name].map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </>
        );
    }

    const handleMaxSpendChange = (e) => {
        setMaxSpend(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = { ...paymentData, max_spend: maxSpend };

        Glitch.api.Campaigns.updateInfluencerCampaign(id, influencer_id, updatedData).then(response => {
            setCampaign(response.data.data);
            setErrors({});
            navigate(Navigate.campaignsPerformanceInfluencer(id, influencer_id));
        }).catch(error => {
            console.error('Error updating influencer campaign', error);
            console.error(error.response.data);
            setErrors(error.response.data || {});

            setTimeout(() => {
                setErrors({});
            }, 5000);
        });
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const renderErrors = () => {
        return Object.keys(errors).map((key) => {
            return errors[key].map((error, index) => (
                <Danger key={`${key}-${index}`} message={error} />
            ));
        });
    };

    return (
        <>
            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min">
                <div className="container">
                    <Breadcrumbs items={[
                        { name: 'Campaigns', link: Navigate.campaignsPage() },
                        { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                        { name: 'Customize Rate Card', link: Navigate.campaignsUpdateInfluencer(campaign.id, influencer_id) },
                    ]}
                    />
                    <div className="section-wrapper text-center">
                        <div className="pageheader-thumb mb-4">
                        </div>
                        <h2 className="pageheader-title">Customize Rate Card</h2>
                        <p className="lead">Customize the influencer's rate card for their campaign.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <CampaignNavbar campaignId={id} />
            </div>

            <div className="container my-5">
                <div className="card text-black">
                    <div className="card-header">
                        <h3 className='text-black'>Update Payment Details for {user.first_name} {user.last_name}</h3>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <p className='lead'>Each influencer can have customized rates set for them if you make individual deals. Modify the rate cards and deliverables below to set custom deals for this influencer.</p>
                            {createTextAreaField('deliverables', 'Deliverables', 'Specify any specific deliverables that you want the influencer to execute during the campaign.', errors, false)}
                            {createInputField('days_to_complete', 'Days To Complete', 'The number of days that the influencer has to complete the deliverables. Leave blank or enter 0 for infinite.', 'number', errors, false)}
                            {createTextAreaField('usage_rights', 'Content Usage Rights', 'Specify the content ownership and usage rights for the content generated during the campaign.', errors, false)}
                            <div className="mb-3">
                                <label htmlFor="maxSpend" className="form-label">Budget Cap</label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="maxSpend"
                                        name="maxSpend"
                                        value={maxSpend || ''}
                                        onChange={handleMaxSpendChange}
                                    />
                                </div>
                                <p>This sets a cap on the maximum amount the influencer can make from this campaign.</p>
                                {errors.max_spend && <Danger message={errors.max_spend[0]} />}
                            </div>
                            <hr />

                            <div className='text-start'>

                                <h6 className='text-black'>Disable Social Media Platforms</h6>
                                <p>By default, influencers can create content for all the listed social platforms. If you want to limit where they can promote your game by disabling certain platforms, select the platforms you want to disable below.</p>


                                <Form.Check
                                    type="checkbox"
                                    label="Disable TikTok"
                                    name="disable_tiktok"
                                    checked={paymentData.disable_tiktok}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Instagram"
                                    name="disable_instagram"
                                    checked={paymentData.disable_instagram}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Twitter"
                                    name="disable_twitter"
                                    checked={paymentData.disable_twitter}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Twitch"
                                    name="disable_twitch"
                                    checked={paymentData.disable_twitch}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable YouTube"
                                    name="disable_youtube"
                                    checked={paymentData.disable_youtube}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Reddit"
                                    name="disable_reddit"
                                    checked={paymentData.disable_reddit}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Facebook"
                                    name="disable_facebook"
                                    checked={paymentData.disable_facebook}
                                    onChange={handleCheckboxChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Disable Kick"
                                    name="disable_kick"
                                    checked={paymentData.disable_kick}
                                    onChange={handleCheckboxChange}
                                />

                                <p className='text-left mt-2'>If you want to fine-tune payment options for each social platform, customize the rate cards below.</p>
                            </div>

                            <Accordion defaultActiveKey="0" className="custom-accordion">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>General Payment Settings</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm
                                            title="General Payment Settings"
                                            campaignData={paymentData}
                                            setPaymentData={setPaymentData}
                                            errors={errors}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                {!paymentData.disable_tiktok && (
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>TikTok Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="TikTok Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="tiktok"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_facebook && (

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Facebook Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Facebook Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="facebook"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_twitch && (
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Twitch Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Twitch Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="twitch"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_youtube && (
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>YouTube Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="YouTube Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="youtube"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_reddit && (
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Reddit Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Reddit Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="reddit"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_kick && (
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Kick Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Kick Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="kick"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_twitter && (
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>Twitter Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Twitter Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="twitter"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {!paymentData.disable_instagram && (
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>Instagram Payment Settings</Accordion.Header>
                                        <Accordion.Body>
                                            <CampaignPaymentForm
                                                title="Instagram Payment Settings"
                                                campaignData={paymentData}
                                                setPaymentData={setPaymentData}
                                                social="instagram"
                                                errors={errors}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                            <br />
                            {renderErrors()}
                            <div className="text-center mt-4">
                                <p>When you update any of the payment information or deliverables, the influencer will be emailed notifying them about the changes to their rate card.</p>
                                <button type="submit" className="btn btn-primary btn-lg">Update Payments</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CampaignUpdateInfluencerPage;
